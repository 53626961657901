import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Proptypes from 'prop-types';

const UploadTopContent = ({
  setView,
  view,
  setIsThumbnail,
  setAlertCategory,
  setIsDownloadableContent,
  setThumbnail,
  setThumbNailList,
  setIsPhoto,
  setIsVideo,
  setSenderOrg,
  setAllowDownload
}) => {
  return (
    <div className="top-content">
      <h1>Upload to TMX</h1>
      <Grid container xs={10} md={12} spacing={3} justifyContent="center" alignItems="center">
        <Grid xs={10} md={12}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="gender"
              name="row-radio-buttons-group"
              value={view}
              onChange={(e) => {
                setView(e.target.value);
                setIsDownloadableContent(false);
                setThumbnail([]);
                setThumbNailList([]);
                setIsPhoto(false);
                setIsVideo(false);
                if (e.target.value !== 'content') {
                  setIsThumbnail(false);
                }
                if (e.target.value === 'pitches') {
                  setAlertCategory('none');
                }
                if (e.target.value === 'content') {
                  setAllowDownload(true);
                }
                if (e.target.value === 'article') {
                  setSenderOrg('TMX');
                }
              }}>
              <FormControlLabel value="INBOX" control={<Radio />} label="TMX Inbox" />
              <FormControlLabel value="article" control={<Radio />} label="Publishable Copy" />
              <FormControlLabel
                value="content"
                control={<Radio />}
                label="Cleared Video/Photo(s)"
              />
              <FormControlLabel value="contact" control={<Radio />} label="Story Contacts" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default UploadTopContent;
UploadTopContent.propTypes = {
  setView: Proptypes.func,
  view: Proptypes.string,
  setAlertCategory: Proptypes.func,
  setIsThumbnail: Proptypes.func,
  setIsDownloadableContent: Proptypes.func,
  setThumbnail: Proptypes.func,
  setThumbNailList: Proptypes.func,
  setIsPhoto: Proptypes.func,
  setIsVideo: Proptypes.func,
  setSenderOrg: Proptypes.func,
  setAllowDownload: Proptypes.func
};
